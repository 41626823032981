#main {
    .form {

        h2 {
            padding-top: 2rem;
            border-top: 1px solid $enb-steel;

            &:first-of-type {
                padding-top: 0;
                border-top: none;
            }
        }

        input {
            max-width: 600px;
            font-size: 1rem;

            &:invalid {
                border-color: $enb-cherry;
            }
        }

        ol {
            list-style: none;
            margin-top: 0.5rem;

            li {
                white-space: nowrap;

                input[type="checkbox"] + label, input[type="radio"] + label {
                    vertical-align: middle;
                    white-space: normal;
                }
            }
        }

        .error-message {
            display: none;
            margin-top: -0.8rem;
            margin-bottom: 1rem;
            font-size: 0.9rem;
            color: $enb-cherry;
        }

        .validation-message, .fieldValidationError {
            color: $enb-cherry;
        }

        input[type=text].validation-error-outline {
            border-color: $enb-cherry;
        }

        .notice {
            padding: 1rem;
            background: $enb-light-grey;
            border: 1px solid $enb-steel;
        }
    }
}
