#page-footer {
	background-color: $enb-charcoal;
	padding: .9375rem;
	color: $white;
	text-transform: uppercase;
	font-size: .6875rem;
	
	ul {
		font-size: inherit;
	}
	
	a {
		color: $white;
		
		&:hover {
			color: $enb-yellow;
		}
	}
	
	.scroll-to-top {
		text-align: center;
		
		.button {
			background-color: $enb-grey;
			margin-bottom: .9375rem;
			
			&:hover {
				color: $enb-charcoal;
			}
			&:after {
				background-color: $white;
			}
			
			img {
				width: 14px;
				margin-bottom: 5px;
				-moz-transform: scaleY(-1);
				-o-transform: scaleY(-1);
				-webkit-transform: scaleY(-1);
				transform: scaleY(-1);
				filter: FlipV;
				-ms-filter: "FlipV";
			}
		}
	}
	
	.footer-copyright {
		@media #{$small-only} {
			text-align: center;
			margin-bottom: .9375rem;
		}
	}
	
	.footer-links {
		text-align: right;
		
		@media #{$small-only} {
			text-align: center;
		}
		
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		
		li {
			display: inline-block;
			margin: 0;
			padding: 0;
			
			&:before {
				content: "|"
			}
			
			&:first-child:before {
				display: none;
			}
		}
		
		a {
			margin: 0 6px;
			text-decoration: none;
			font-family: $text-bold;
		}
	}
}

