//https://foundation.zurb.com/building-blocks/blocks/responsive-card-table.html

$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 1px solid $enb-charcoal;
$responsive-card-table-label-bg: $enb-soft-yellow;

.responsive-card-table {
  border-collapse: collapse;

  &.unstriped tbody {
    background-color: transparent;
  }

  tr,
  th,
  td {
    border: $responsive-card-table-border;
  }

  @media #{$small-only} {
    thead tr {
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tr {
      border: $responsive-card-table-border;
      display: block;
    }
	
	tr:nth-child(even) {
		background-color: transparent !important;
	}

    tr + tr {
      margin-top: $responsive-card-table-bottom-gap;
    }
    /* Get table cells to act like rows */

    td {
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: $responsive-card-table-border;
      padding-left: 50% !important;
      position: relative;

      &:before {
        content: attr(data-label);
        display: inline-block;
        font-weight: bold;
        line-height: 1.5;
        margin-left: -100%;
        width: 100%;
        position: relative;
        z-index: 1;
		flex: none;
      }

      &:after {
        content: '';
        position: absolute;
        background: $responsive-card-table-label-bg;
        width: 45%;
        height: 95%;
        left: 1px;
        top: 1px;
        z-index: 0;
      }
    }
  }
}

