body.preview-mode {
    #page-header {
        top: 86px;
    }
}

#main {
    padding-top: 68px;
    background: $white;
    min-height: calc(100% - 46px);

    @media #{$small-only} {
        min-height: calc(100% - 132px);
        padding-top: 95px;
    }

    .content {
        padding: 2rem 1.0625rem;

        :first-child {
            margin-top: 0;
        }

        .content-columns {
            @media #{$xxlarge-only} {
                column-count: 3;
            }

            @media #{$xlarge-only} {
                column-count: 2;
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;

            thead, tr:nth-of-type(even) {
                background: none;
            }

            th, td {
                color: $enb-charcoal;
                font-size: .875rem;
                line-height: 1.4;
                padding: .8125rem;
                vertical-align: top;
            }

            th {
                border: 1px solid $enb-steel;
                font-family: "fs-bold",Arial,sans-serif;
            }

            td {
                border: 1px solid $enb-steel;
            }

            // Add background to header row
            &.header--background th {
                background-color: #eaeaed;
            }

            // Add alternating background starting with 2nd row
            &.table--stripes tr:nth-of-type(even) {
                background-color: $enb-soft-yellow;
            }

            // Add hover state to rows
            &.hover--rows {
                tr:hover td {
                    background-color: #e8e8e8;
                    -moz-transition: background 0.3s ease;
                    -webkit-transition: background 0.3s ease;
                    transition: opacity background 0.3s ease;
                    //cursor: pointer;
                }
            }
        }
    }
}