#main {
	.content.tiles {
		padding: 0;
		margin: 2rem 1.0625rem;
		display: flex;
		flex-wrap: wrap;
		
		@media #{$medium-down} {
			display: block;
		}
		
		.banner-tile-alt {
			padding: 0;
			text-align: center;
			margin-bottom: 2rem;
			
			.banner-tile-text {
				background: #fff6e3;
				padding: 3rem;
				border: 1px solid gray;
				
				p {
					margin-bottom: 0;
				}
			}
		}
		
		.tile {
			height: 300px;
			margin-left: 2rem;
			padding: 0;
			position: relative;
			background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			flex: 1;

			@media #{$medium-down} {
				margin-left: 0;
				margin-top: 2rem;
			}
			
			&:first-of-type {
				margin-left: 0;
				margin-top: 0;
			}
			
			.container {
				width: 100%;
				height: 100%;
				display: block;
				position: relative;
				z-index: 1;
				
				.tile-content {
					position: absolute;
					bottom: 0;
					padding: 2rem;
					@include font-smoothing;
					@include text-shadow;
					color: $white;
					letter-spacing: -1px;
					font-size: 1.5rem;
					line-height: 1.7rem;
				}
				
				.tile-cta {
					position: absolute;
					width: 100%;
					top:0;
					background-color: rgba(10,10,10,0.4);
					text-decoration: none;
					padding: .5rem 1rem;;
					@include font-smoothing;
					@include text-shadow;
					color: $white;
					letter-spacing: -1px;
					font-size: 1.5rem;
					font-family: $text-bold;
					
					.plus {
						display: none;
					}
				}
			}
			
			a.container {
				.tile-cta {
				
					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 0;
						z-index: -1;
						transition: height 0.1s;
						background-color: $enb-yellow;
					}
					
					.plus {
						display: block;
						position: absolute;
						right: 1rem;
						top: .9rem;
						height: 1.5rem;
						width: 1.5rem;
						fill: $enb-yellow;
						transform: rotate(0deg);
						transition: transform .2s;
					}
				}
				
				&:hover .tile-cta {
					background: none;
					color: $enb-grey;
					text-shadow: none;
					
					.plus {
						transform: rotate(90deg);
						transition: transform .2s;
						fill: $white;
					}
					
					&:after {
						height: 100%;
					}
				}
			}
		}
	}
}