// We call these first because we use these new
// vars etc in the Zurb settings file
// - - - - - - - - - - - - - - - - - - - - - - - -
@import "base/fonts";                                                                                                                                                       
@import "base/variables";

// Zurb Foundation        
// - - - - - - - - - - - - - - - - - - - - - - - - -         
@import "settings";
@import "foundation/components/grid";
@import "foundation/components/type";
@import "foundation/components/buttons";
@import "foundation/components/visibility"; // Show/hide at different break points
@import "foundation/components/inline-lists";
@import "foundation/components/forms";
@import "foundation/components/reveal";
@import "foundation/components/block-grid";
@import "foundation/components/tables";
@import "foundation/components/responsive-tables";
 
// Base & global
// - - - - - - - - - - - - - - - - - - - - - - - - -
@import "base/mixins";
@import "base/type";

 
// Components
// - - - - - - - - - - - - - - - - - - - - - - - - -
@import "components/header";
@import "components/banner";
@import "components/main-content";
@import "components/footer";
@import "components/buttons";
@import "components/tiles";
@import "components/form";

 
// Copy and paste into Zurb section as needed
// - - - - - - - - - - - - - - - - - - - - - - - - -

//   "foundation/components/accordion",
//   "foundation/components/alert-boxes",
//   "foundation/components/block-grid",
//   "foundation/components/breadcrumbs",
//   "foundation/components/button-groups",
//   "foundation/components/buttons",
//   "foundation/components/clearing",
//   "foundation/components/dropdown",
//   "foundation/components/dropdown-buttons",
//   "foundation/components/flex-video",
//   "foundation/components/forms",
//   "foundation/components/grid",
//   "foundation/components/inline-lists",
//   "foundation/components/joyride",
//   "foundation/components/keystrokes",
//   "foundation/components/labels",
//   "foundation/components/magellan",
//   "foundation/components/orbit",
//   "foundation/components/pagination",
//   "foundation/components/panels",
//   "foundation/components/pricing-tables",
//   "foundation/components/progress-bars",
//   "foundation/components/reveal",
//   "foundation/components/side-nav",
//   "foundation/components/split-buttons",
//   "foundation/components/sub-nav",
//   "foundation/components/switches",
//   "foundation/components/tables",
//   "foundation/components/tabs",
//   "foundation/components/thumbs",
//   "foundation/components/tooltips",
//   "foundation/components/top-bar",
//   "foundation/components/type",
//   "foundation/components/offcanvas",
//   "foundation/components/visibility";

