#page-header {
	background-color: $enb-charcoal;
	padding: .9375rem;
	color: $white;
	position: fixed;
	width: 100%;
	min-height: 68px;
	top: 0;
	left: 0;
	z-index: 100;
	
	@media #{$small-only} {
//		min-height: 128px;
	}
	
	.flex {
		display: flex;
		
		@media #{$small-only} {
			display: block;
		}
		
		.flex-bottom {
			align-self: flex-end;
		}
	}
	
	a {
		color: $white;
		
		&:hover {
			color: $enb-yellow;
		}
	}
	
	.logo {
		@media #{$small-only} {
			text-align: center;
		}
	}
	
	.site-links {
		margin-bottom: -10px;
		text-align: right;
		overflow: hidden;
		
		@media #{$small-only} {
			text-align: center;
			margin-top: 1rem;
		}
		
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			float: right;
			margin-right: -2px;
			
			@media #{$large-down} {
				float: none;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}
		
		li {
			float: left;
			margin: 0;
			padding: 0;
			flex-grow: 1;
			flex-basis: auto;
			border-right: 2px solid white;
			margin-bottom: .5rem;
			text-align: center;
			height: 0.8rem;
			line-height: 0.9rem;
			
			&:before {
				//content: "|"
			}
			
			&:first-child:before {
				//display: none;
			}
		}
		
		a {
			display: inline-block;
			margin: 0 0.7rem;
			text-decoration: none;
			font-family: $text-bold;
		}
	}
}
