// NAME
// - - - - - - - - - - - - - - - - - - - - - - - - -

// CLEARFIX
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Fixes blurry edges on white text on Macs
// - - - - - - - - - - - - - - - - - - - - - - - - -
@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

// Blur background
// - - - - - - - - - - - - - - - - - - - - - - - - -
@mixin blur {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
    overflow: hidden;
}

@mixin outline {
    outline: 2px dashed orange;
}

// VERTICAL ALIGN
@mixin vertical-align {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// VERTICAL ALIGN FOR TALL TILES
@mixin vertical-align-tall {
    position: absolute;
    top: 25%;
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
}

// HORIZONTAL ALIGN
@mixin horizontal-align {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

// TEXT SHADOW - used mostly for image-bg-tiles
@mixin text-shadow {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}

// BACKGROUNDS
// - - - - - - - - - - - - - - - - - - - - - - - - -
// YELLOW BACKGROUND
@mixin yellow-bg {
    background-color: $enb-yellow;
}

// RED BACKGROUND
@mixin red-bg {
    background-color: $enb-cherry;
}

// LIGHT GREY BACKGROUND
@mixin light-grey-bg {
    background-color: $enb-light-grey;
}

// DARK GREY BACKGROUND
@mixin dark-grey-bg {
    background-color: $enb-charcoal;
}

// SOFT YELLOW BACKGROUND
@mixin soft-yellow-bg {
    background-color: $enb-soft-yellow;
}


// Default image for tiles
// - - - - - - - - - - - - - - - - - - - - - - - - -
@mixin image-bg {
    background-image: linear-gradient( rgba(0,0,0,0.3), rgba(255,255,255,0.1) );
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


// Make things PINK for trubble shooting
@mixin pink {
    background-color: pink;
}
// Apply a 24 column grid to the back of a .row
@mixin griddy {
    background-image: url("../img/bg-grid.png");
    background-position: center top;
    background-repeat: repeat-y;
}

@mixin translate3d($tx, $ty, $tz) {
    -webkit-transform: translate3d($tx, $ty, $tz);
    -moz-transform: translate3d($tx, $ty, $tz);
    -ms-transform: translate($tx, $ty);
    -ms-transform: translate3d($tx, $ty, $tz);
    -o-transform: translate3d($tx, $ty, $tz);
    transform: translate3d($tx, $ty, $tz);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
}

@mixin slideFromLeft($distance, $time) {
    -webkit-transition-duration: $time;
    -moz-transition-duration: $time;
    -o-transition-duration: $time;
    transition-duration: $time;
    @include translate3d($distance, 0, 0);
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
    $responsive-unitless: $responsive / ($responsive - $responsive + 1);
    $dimension: if(unit($responsive) == 'vh', 'height', 'width');
    $min-breakpoint: $min / $responsive-unitless * 100;

    @media (max-#{$dimension}: #{$min-breakpoint}) {
        font-size: $min;
    }

    @if $max {
        $max-breakpoint: $max / $responsive-unitless * 100;

        @media (min-#{$dimension}: #{$max-breakpoint}) {
            font-size: $max;
        }
    }

    @if $fallback {
        font-size: $fallback;
    }

    font-size: $responsive;
}

