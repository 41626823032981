#main {
    .banner {
        position: relative;
        height: 500px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        text-align: center;

        .banner-content {
            width: 100%;
            @include vertical-align;

            .banner-text {
                padding: 0 4rem;
                margin-left: auto;
                margin-right: auto;
                text-align: left;
                @include font-smoothing;
                @include text-shadow;
                color: $white;
                letter-spacing: -1px;

                strong {
                    color: $enb-yellow;
                }

                h1 {
                    color: $white;
                    font-size: 3rem;
                }

                p {
                    font-size: 1.5rem;
                }

                @media #{$medium-only} {
                    padding: 0 4rem;
                }

                @media #{$small-only} {
                    padding: 0 1rem;
                }
            }

            .banner-tile {
                position: absolute;
                top: 50%;
                right: 0;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);

                .banner-tile-text {
                    width: 80%;
                    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
                    background: #fff6e3;
                    padding: 3rem;
                    border: 1px solid gray;

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media #{$medium-down} {
            height: 400px;
        }

        &.short {
            height: 200px;

            .banner-content {
                .banner-text {
                    padding: 0 2rem;

                    h1 {
                        @media #{$small-only} {
                            font-size: 2.5rem;
                        }
                    }

                    p {
                        @media #{$medium-down} {
                            display: none;
                        }
                    }
                }
            }
        }

        &.large {
            .banner-content {
                .banner-text {
                    padding: 0 4rem;
                    width: 70%;
                }
            }
        }
    }
}
