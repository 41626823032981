// Headings
// - - - - - - - - - - - - - - - - - - - - - - - - -
h1, h2, h3, h4, h5, h6 {
    color: $enb-charcoal;
    font-family: $text-bold;
    letter-spacing: -.1rem;
    line-height: 1;
}

h1 {
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
}

h2 {
    font-size: 1.7rem;
    margin: 2rem 0 1rem 0;
}

h3 {
    font-size: 1.5rem;
    letter-spacing: -.05rem;
    margin: 2rem 0 1rem 0;
}

h4 {
    font-size: 1.3rem;
    letter-spacing: -.05rem;
    margin: 2rem 0 1rem 0;
}

h5 {
    font-size: 1.1rem;
    letter-spacing: -.05rem;
    margin: 2rem 0;
}

h6 {
    font-size: 1rem;
    letter-spacing: -.05rem;
    margin: 2rem 0;
}

p a, dl a {
    text-decoration: underline;
}


// Text styling classes
// - - - - - - - - - - - - - - - - - - - - - - - - -
.text--black {
    font-family: $text-black;
}

.text--black-italic {
    font-family: $text-black-italic;
}

.text--bold {
    font-family: $text-bold;
}

.text--bold-italic {
    font-family: $text-bold-italic;
}

.text--italic {
    font-family: $text-italic;
}

.text--light {
    font-family: $text-light;
}

.text--light-italic {
    font-family: $text-light-italic;
}

.text--roman {
    font-family: $text-roman;
}

// Text colour classes
// - - - - - - - - - - - - - - - - - - - - - - - - -
.text--yellow {
    color: $enb-yellow;
}

.text--white {
    color: $white;
}

.text--black {
    color: $black;
}

.text--charcoal {
    color: $enb-charcoal;
}


a {
    text-decoration: underline;
}

a:visited {
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: underline;
}

a.btn:active {
    text-decoration: none;
}
