// Standard button
// - - - - - - - - - - - - - - - - - - - - - - - - -

.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    -webkit-animation-duration: 0s !important;
    animation-duration: 0s !important;
}

%btn {
    @include font-smoothing;
    background-color: $enb-charcoal;
    color: $white;
    font-family: $text-bold;
    text-align: center;
    display: inline-block;
    letter-spacing: .05em;
    line-height: 1.1;
    padding: rem-calc(10 30);
    text-decoration: none;
    text-rendering: optimizeLegibility;
    transition: height .2s linear;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    white-space: nowrap;

    &.generated-button {
        margin: 2px;

        .plus {
            margin-bottom: 3px;
            margin-left: 6px;
        }
    }

    &:focus {
        color: $white;
    }

    @media (min-width: $max-content-size) {
        padding: .6em 1em;
        font-size: 1em;
    }

    @media #{$xlarge-only} {
        padding: .65vw 1.4vw;
        font-size: 1.21vw;
        //letter-spacing: .035em;
    }

    @media #{$large-only} {
        padding: 1vw 2.5vw;
        font-size: 1.7vw;
        //letter-spacing: .035em;
    }

    @media #{$medium-only} {
        padding: 1.4vw 3.7vw;
        font-size: 2.5vw;
        //letter-spacing: .035em;
    }

    @media #{$small-only} {
        padding: 2.3vw 6.6vw;
        font-size: 4vw;
        //letter-spacing: .035em;
    }

    .overview-text {
        vertical-align: text-bottom;
        display: inline-table;
    }

    .plus {
        text-align: center;
        width: 13px;
        height: 13px;
        margin: 0 0 2px 3px;
        vertical-align: middle;
        border-radius: 50%;
        animation: plus-out .2s;
        transition-delay: .07s;
        /*@media #{$small-only} {
            width:11px;
            height:11px;    
        }*/
    }
    //firefox fixes
    @-moz-document url-prefix() {
        svg {
            transform-origin: center center;
        }

        &:hover svg { //}, &:focus svg {
            fill: $white;
        } 
        &:focus svg {
            fill: $enb-yellow;
        }
        &:focus:hover svg {
            fill: $white;
        }
        //end firefox fixes
    }

    svg {
        transition-delay: .02s;
        fill: $enb-yellow;
        margin-top: -2.5px;
    }

    &.plus-active svg {
        animation: plus-click .5s !important;

        @-moz-document url-prefix() {
            animation: plus-click .5s forwards !important;
        }
    }

    &.plus-active-alt svg {
        animation: plus-click2 .5s !important;

        @-moz-document url-prefix() {
            animation: plus-click2 .5s forwards !important;
        }
    }

    @-webkit-keyframes plus-click {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(180deg);
        }
    }

    @keyframes plus-click {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(180deg);
        }
    }

    @-webkit-keyframes plus-click2 {
        0% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes plus-click2 {
        0% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &:hover {
        color: $enb-grey;

        .plus {
            animation: plus-in .2s;
            transition-delay: .03s;
            fill: white;
        }

        @-webkit-keyframes plus-in {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(90deg);
            }
        }

        @keyframes plus-in {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(90deg);
            }
        }

        @-webkit-keyframes plus-out {
            0% {
                transform: rotate(90deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }

        @keyframes plus-out {
            0% {
                transform: rotate(90deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }
    }


    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        z-index: -1;
        transition: height 0.1s;
        background-color: $enb-yellow;
    }

    &:hover:after {
        height: 100%;
    }
}

.btn{
	@extend %btn;
}

.btn--small {
    padding: rem-calc(10 20);
}

svg {
    pointer-events: none;
}
/* Overview button behavior on content page*/
.btn.highlight-style {
    @include font-smoothing;
    background-color: $white;
    text-align: left;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0em;
    line-height: 1.1;
    padding: rem-calc(10 30);
    text-decoration: none;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
    transition: width .3s linear;

    svg {
        transition-delay: 0.05s;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 100%;
        z-index: -1;
        transition: width 0.3s;
    }

    &:hover:after {
        width: 100%;
    }

    &:focus {
        color: $black;
    }

    &:hover svg {
        transition-delay: 0.25s;
        -moz-transition-delay: 0.25s;
        -webkit-transition-delay: 0.25s;
        fill: white;
    }
}

.btn-map-view-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    text-align: center;
    cursor: pointer;

    &:hover .btn-map-view-top {
        width: 25px;
        margin-right: -30px;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }

    a {
        .map-button-map-view {
            .regular-text {
                vertical-align: super;
                position: relative;
                top: -3px;
            }
        }

        .map-button-list-view {
            .regular-text {
                vertical-align: super;
                position: relative;
                top: 2px;
            }
        }

        img {
            vertical-align: baseline;
        }
    }
}

.project-details-page {
    @media #{$medium-only} {
        .btn-map-view-wrapper {
            width: 50%;
            margin: 0 auto;
        }
    }
}

.btn-map-view-top {

    @include font-smoothing;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    font-family: $text-bold;
    background-color: $enb-yellow;
    color: black;
    padding: rem-calc(16 0);
    margin: 0;
    text-transform: uppercase;
    transition: width 0.2s, padding 0.2s, margin 0.2s;
    white-space: nowrap;
    overflow: hidden;
    z-index: 2;

    img {
        overflow: hidden;
    }
}

.btn-map-view-below {

    @include font-smoothing;
    font-family: $text-bold;
    background-color: $enb-grey;
    padding: rem-calc(16 0);
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;
    color: $white;

    img {
        height: rem-calc(25);
        width: rem-calc(25);
    }

    a {
        color: $white;
    }
}

.btn-map-view-wrapper {
    .btn-map-view-top,
    .btn-map-view-below {
        img {
            height: rem-calc(25);
            width: rem-calc(25);
            vertical-align: bottom;
        }
    }
}

.projects-landing {
    .btn-map-view-wrapper {
        .btn-map-view-top,
        .btn-map-view-below {
            img {
                vertical-align: baseline;
            }

            margin-top: -2px;
        }
    }
}

.map-template {
    .btn-map-view-wrapper {
        .btn-map-view-top,
        .btn-map-view-below {
            img {
                vertical-align: top;
                margin-top: -2px;
            }
        }
    }
}

.wrapper-filter {
    .btn-map-view-wrapper {
        width: 20%;
        height: 55px;
        float: right;
        font-size: .8rem;

        @media #{$medium-only} {
            width: 25%;
        }

        @media #{$small-only} {
        }
    }
}

.project-details-page {
    .btn {
        @media #{$large-only} {
            padding: .9vw 2.1vw;
            font-size: 1.6vw;
            letter-spacing: .035em;

            .plus {
                margin-bottom: 3px;
            }
        }
    }
}

.mobile-filter-panel1 {
    .btn {
        @media #{$small-only} {
            font-size: 1.125rem;
            padding-top: 13px;
            padding-bottom: 13px;

            &:after {
                background-color: $enb-charcoal;
            }

            &:hover {
                color: $white;
            }
        }
    }
}

.btn-text.button-borders {
    border-top: 1px solid $enb-steel;
    border-bottom: 1px solid $enb-steel;
    margin-right: rem-calc(10);

    &:hover {
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
    }
}
