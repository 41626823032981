// -----------------------------------------------------------------------------
// Foundation Sans Fonts
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Black
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-black';
	src: url('../fonts/fs-black/FoundationSans-Black-webfont.eot');
	src: url('../fonts/fs-black/FoundationSans-Black-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-black/FoundationSans-Black-webfont.woff') format('woff'),
	     url('../fonts/fs-black/FoundationSans-Black-webfont.ttf') format('truetype'),
	     url('../fonts/fs-black/FoundationSans-Black-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}

// -----------------------------------------------------------------------------
// Black Italic
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-black-italic';
	src: url('../fonts/fs-blackitalic/FoundationSans-BlackItalic-webfont.eot');
	src: url('../fonts/fs-blackitalic/FoundationSans-BlackItalic-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-blackitalic/FoundationSans-BlackItalic-webfont.woff') format('woff'),
	     url('../fonts/fs-blackitalic/FoundationSans-BlackItalic-webfont.ttf') format('truetype'),
	     url('../fonts/fs-blackitalic/FoundationSans-BlackItalic-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;

}

// -----------------------------------------------------------------------------
// Bold
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-bold';
	src: url('../fonts/fs-bold/FoundationSans-Bold-webfont.eot');
	src: url('../fonts/fs-bold/FoundationSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-bold/FoundationSans-Bold-webfont.woff') format('woff'),
	     url('../fonts/fs-bold/FoundationSans-Bold-webfont.ttf') format('truetype'),
	     url('../fonts/fs-bold/FoundationSans-Bold-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}

// -----------------------------------------------------------------------------
// Bold Italic
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-bold-italic';
	src: url('../fonts/fs-bolditalic/FoundationSans-BoldItalic-webfont.eot');
	src: url('../fonts/fs-bolditalic/FoundationSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-bolditalic/FoundationSans-BoldItalic-webfont.woff') format('woff'),
	     url('../fonts/fs-bolditalic/FoundationSans-BoldItalic-webfont.ttf') format('truetype'),
	     url('../fonts/fs-bolditalic/FoundationSans-BoldItalic-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}

// -----------------------------------------------------------------------------
// Italic
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-italic';
	src: url('../fonts/fs-italic/FoundationSans-Italic-webfont.eot');
	src: url('../fonts/fs-italic/FoundationSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-italic/FoundationSans-Italic-webfont.woff') format('woff'),
	     url('../fonts/fs-italic/FoundationSans-Italic-webfont.ttf') format('truetype'),
	     url('../fonts/fs-italic/FoundationSans-Italic-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}

// -----------------------------------------------------------------------------
// Light
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-light';
	src: url('../fonts/fs-light/FoundationSans-Light-webfont.eot');
	src: url('../fonts/fs-light/FoundationSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-light/FoundationSans-Light-webfont.woff') format('woff'),
	     url('../fonts/fs-light/FoundationSans-Light-webfont.ttf') format('truetype'),
	     url('../fonts/fs-light/FoundationSans-Light-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}

// -----------------------------------------------------------------------------
// Light Italic
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-light-italic';
	src: url('../fonts/fs-lightitalic/FoundationSans-LightItalic-webfont.eot');
	src: url('../fonts/fs-lightitalic/FoundationSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-lightitalic/FoundationSans-LightItalic-webfont.woff') format('woff'),
	     url('../fonts/fs-lightitalic/FoundationSans-LightItalic-webfont.ttf') format('truetype'),
	     url('../fonts/fs-lightitalic/FoundationSans-LightItalic-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}

// -----------------------------------------------------------------------------
// Roman
// -----------------------------------------------------------------------------

@font-face { 
	font-family: 'fs-roman';
	src: url('../fonts/fs-roman/FoundationSans-Roman-webfont.eot');
	src: url('../fonts/fs-roman/FoundationSans-Roman-webfont.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/fs-roman/FoundationSans-Roman-webfont.woff') format('woff'),
	     url('../fonts/fs-roman/FoundationSans-Roman-webfont.ttf') format('truetype'),
	     url('../fonts/fs-roman/FoundationSans-Roman-webfont.svg#webfont') format('svg');
	     font-weight: normal;
		 font-style: normal;
}
